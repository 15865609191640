.wallet-bg-section{
    background-image: url("../../assets/walletbg.png");
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.wingo-period-bg{
    background-image: url("../../assets/wingoissue.png");
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

  
  .container2  .trend-record {
    font-family: Arial, sans-serif;
    position: relative; /* Ensures absolute positioning for SVG */
    display: flex;
    padding: 0 10px;
    justify-content: space-between;  
    height: 70vh;
  }
  .container2 #trendList li{
    height: 3.033333rem;
    padding: .45333rem 0rem;
    border-top: 1px solid;
    border-color: var(--stale-500);
  }
  .popup-select-effect{
    position: relative;
  }
  .popup-select-effect::before{
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 1.78667rem;
    background-image: linear-gradient(172deg, transparent 50%, var(--bg-nav) 50%);
    
  }
  .popup-select-effect::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 1.78667rem;
    background-image: linear-gradient(188deg, transparent 50%, var(--bg-nav) 50%);
    
  }
  .container2  .sec {
    display: flex;
    align-items: center;
   
    
  }
  
  .container2  .sec span {
    margin-right: 5px;
    padding: 2px;
    border-color: var(--slate-500);
    border-width: 1px;
    border-radius: 9999px;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 1rem;
    display: flex;
    color: var(--stale-500);
    font-size: 12px;
  }
  .container2 .third{
    margin-right: 5px;
    padding: 2px;
    border-radius: 9999px;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 1rem;
    display: flex;
    color: var(--white);
    font-size: 12px;
  }
  
  .container2  .active {
   
    color: white !important;
    border-color: transparent !important;
    z-index: 10 !important;
  }
  
  .container2  .svg-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .container2  .line {
    stroke: red;
    stroke-width: 2;
  }
  

  /* trx */

.trx-period-bg{
  background-image: url("../../assets/trxbg.png");
  width: 100%;
  height: 170px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.trx-period-bg .period-img{
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}
.result-period-numers1{
  width: 20px;
  height: 20px;
  /* background-color: var(--red-color-200); */
  border-radius: 50%;

}