#svg circle {
    stroke-dashoffset: 0;
    transition: stroke-dashoffset 1s linear;
    stroke: #666;
    stroke-width: 1em;
  }
  #svg #bar {
    stroke: var(--bg-color-l);
  }
  #cont {
    display: block;
    height: 200px;
    width: 200px;
    margin: 2em auto;
    /* box-shadow: 0 0 1em black; */
    border-radius: 100%;
    position: relative;
  }
  #cont:after {
    position: absolute;
    display: block;
    height: 160px;
    width: 160px;
    left: 50%;
    top: 50%;
    box-shadow: inset 0 0 1em black;
    content: attr(data-pct)"%";
    margin-top: -80px;
    margin-left: -80px;
    border-radius: 100%;
    line-height: 160px;
    font-size: 2em;
    text-shadow: 0 0 0.5em black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .total-img{
    background-image: url("../../assets/TotalAssetsBg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    /* object-fit: scale-down; */
    height: 9.5rem;
  }