
.lottery-banner-section{
 
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    height: 8rem;
    border-radius: .16rem;
    background:  url("../../../assets/LotteryBanner.png"), lightgray 0 -.11107rem / 100% 99.782% no-repeat;
}
.lottery--page-section .lottery-banner-section .insite-banner{



display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    flex-direction: row;
    height: 3.36rem;
    padding: 8px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    background: rgba(98, 47, 0, .7);
    -webkit-backdrop-filter: blur(.05333rem);
    backdrop-filter: blur(.15333rem);
    border-bottom-left-radius: .16rem;
    border-bottom-right-radius: .16rem;
}
.lottery--page-section .gobtn{
    position: absolute;
    bottom: 0;
    right: 0;
    background: #333364;
    font-size: 12px;
    font-weight: 600;
    padding: 3px;
    border-top-left-radius: 47px;
    padding-left: 10px;
    padding-top: 8px;
    color: #00b8ffe6;
    letter-spacing: 0px;
}
.border-after{
    position: relative;
    margin-bottom: .34667rem;
    padding-left: .6667rem;
    font-size: 20px;
    font-weight: 700;
    color: var(--light-gray);
}
.border-after::after{
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: .28rem;
    height: 18px;
    background: var(--main-color);
}


 /* src/Slider.css */
/* src/Slider.css */
/* src/Slider.css */
.winning-item /* src/VerticalSlider.css */
/* src/VerticalSlider.css */
.slider-container {
    overflow: hidden;
 
    position: relative;
  }
  
  .winning-item .slider {
    display: flex;
    flex-direction: column;
  }
  
  .winning-item .slide {
    padding: 12px;
    text-align: center;
    background: var(--bg-light);
    /* border-bottom: 1px solid var(--white); */
    margin-top: 5px;
    display: flex;
   
    border-radius: 10px;
  }
  .winning-item .slide:first-child{
    animation: slide-in 1s ease-in-out forwards;
  }
  .winning-item .slide img{
    width: 50px;
  }
  .winning-item .slide .img2{
    width: 60px;
    height: 50px;
  }
  .winning-item .slide-in {
    animation: slide-in 1s ease-in-out forwards;
    /* animation: slideInFromTop 1s linear; */
  }
  
  @keyframes slide-in {
    from {
      transform: translateY(-20%);
    }
    to {
      transform: translateY(0);
    }
  }
  .slots-img{
background-image: url("../../../assets/plinko.png");
background-repeat: no-repeat;
background-size: cover;
background-position: center;
height: 9rem;
width: 100%;
position: relative;
  }
  .slots-img .slots-item{
position: absolute;
background-image: linear-gradient(180deg,rgba(22,35,56,0) -105.45%,#162338 75.17%);
bottom: 0;
left: 0;
right: 0;
padding: 5px;
}

  