.navbar-section{
    position: fixed;
    bottom: 0;
    left: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 25rem;
    text-align: center;
    -webkit-transform: translateX(-50%);
    transform: translate(-50%);
    /* height: 1.73333rem; */
    /* padding-block: 0 .13333rem;
    font-size: .29333rem; */
    height: 4rem;
    z-index: 100;
    padding: 0 10px;
    padding-top: 15px;
    background-color: var(--bg-nav);
}
.nav-promotion{
    width: 60px;
    height: 60px;
    position: relative;
    /* background: rebeccapurple; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    border: 3px solid white;
    /* top: -24px; */
    margin-top: -37px;
   background-image:  linear-gradient(180deg, #3C9CF6 0%, #4DD8EF 100%);
}
.nav-promotion svg{
    margin-top: 8px;
    font-size: 32px;
}
@media screen and (max-width: 500px) {
    .navbar-section {
               width: 100%;
    }
}
.navbar-section .active{
    color: var( --main-color);
}
.navbar-section >div{
    cursor: pointer;
}