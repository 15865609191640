/* For the scrollbar track */
.sidebar::-webkit-scrollbar {
    width: 1px;
  }
  
  /* For the scrollbar thumb */
  .sidebar::-webkit-scrollbar-thumb {
    width: 1px;
    background-color: #888; /* Customize the scrollbar color */
  }
  
  /* Optional: Add this for a visible scrollbar thumb on hover */
  .sidebar::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Darker color on hover */
  }
  